import React, { useRef, useState } from "react";
import { useAppSelector } from "../../../app/hooks";
import { useGetCryptoTransactionHistoryQuery } from "../../../features/api/walletSlice";
import TableMain from "../../reusables/ReactDataTable";
import { columnsCrypto } from "../../hotspotHubs/ReactTableDataList";
import { MobileFilterSearch } from "../../hotspotHubs/OptionsStatus";
import useOutsideClick from "../../../Hooks/useOutsideClick";
import { EmptyCard, Filter, TableLoader } from "../../reusables";

const Crypto: React.FC<TransationProps> = ({ show, showFilter }) => {
  const { userId } = useAppSelector((state) => state.auth);
  const [option, toggleOption] = useState<string>("");
  const [status, setStatus] = useState<string>("All");
  const [duration, setDuration] = useState<string>("All");
  const [currencyCode, setCurrencyCode] = useState<string>("All");
  const [reference, setReference] = useState<string>("");
  const [type, setType] = useState<string>("All");
  const [page, setPage] = useState<number>(1);

  const modalRef = useRef<HTMLElement>(null);

  const handleCloseModal = () => {
    toggleOption("");
  };

  useOutsideClick(modalRef, handleCloseModal);
  const { data: history, isLoading: gettingHistory, isFetching} =
    useGetCryptoTransactionHistoryQuery({
      userId,
      page,
      pageSize: 30,
      status,
      type: type === "All" ? "" : type,
      currencyCode: currencyCode === "All" ? "" : currencyCode,
      reference,
      duration,
    }, {skip: !userId});

  if (!show) return <></>;
  return (
    <div>
      {showFilter && (
        <div className="flex flex-wrap w-full gap-x-3 gap-y-7 md:px-4 md:w-auto">
          <MobileFilterSearch>
            <Filter
              options={["All","Credit", "Debit"]}
              toggleFilter={toggleOption}
              selectedFilterOption={option}
              currentFilterOption={"TYPE"}
              selectFilterOption={setType}
              selectedOption={type}
              modalRef={modalRef}
              label={"Type"}
            />
            <Filter
              options={[
                "All",
                "LastWeek",
                "LastMonth",
                "Last3Months",
                "LastYear",
              ]}
              toggleFilter={toggleOption}
              selectedFilterOption={option}
              currentFilterOption={"DURATION"}
              selectFilterOption={setDuration}
              selectedOption={duration}
              modalRef={modalRef}
              label={"Duration"}
            />

            <Filter
              options={[
                "All",
                "USDT",
                "WNT",
              ]}
              toggleFilter={toggleOption}
              selectedFilterOption={option}
              currentFilterOption={"CURRENCY"}
              selectFilterOption={setCurrencyCode}
              selectedOption={currencyCode}
              modalRef={modalRef}
              label={"Currency"}
            />
            <Filter
              options={[
                "All",
                "Pending",
                "Confirmed",
                "Failed",
              ]}
              toggleFilter={toggleOption}
              selectedFilterOption={option}
              currentFilterOption={"STATUS"}
              selectFilterOption={setStatus}
              selectedOption={status}
              modalRef={modalRef}
              label={"Status"}
            />
          </MobileFilterSearch>
          <div className="flex-1 md:flex-auto ">
            <label
              htmlFor=""
              className="hidden md:block mb-2.5 text-xs font-semibold text-clrTextLabel dark:text-clrPlaceholder"
            >
              Reference
            </label>
            <input
              type="text"
              name=""
              id=""
              value={reference}
              onChange={(e)=> setReference(e.target.value)}
              placeholder="Enter reference here"
              className="w-full md:w-fit text-xs font-medium text-clrGray border-[1px] border-solid border-clrBorder dark:border-clrGray rounded-3xl h-8 pb-1 px-4 placeholder:text-clrPlaceholder placeholder:text-[10px] bg-inherit"
            />
          </div>
        </div>
      )}
      {gettingHistory || isFetching ? (
        <TableLoader />
      ) : (
        <TableMain
          showPagingation={showFilter}
          defaultData={history?.data.records || []}
          columns={columnsCrypto}
          totalPages={history?.data.totalPages || 0}
          pageSize={history?.data.pageSize || 0}
          currentPage = {history?.data.currentPage || 0}
          setPage={setPage}
        />
      )}
      {!gettingHistory && !isFetching && (history?.data?.records.length ?? 0) === 0 && (
        <EmptyCard description="Make a crypto transaction to view your history" />
      )}
    </div>
  );
};

export default Crypto;
