import { useState } from "react";
import { useAppSelector } from "../../app/hooks";
import { ReactComponent as CopyIcon } from "../../assets/icons/copy.svg";
import { copyToClipboard } from "../../Helper";
import ReferralList from "./ReferralList";

const Referrals = () => {
  const { referralCode: refCode } = useAppSelector((state) => state.auth);
  const [copiedText, setCopied] = useState<string>("");

  return (
    <section className="w-[96%] mx-auto pb-6 mt-3 overflow-y-auto">
      <div className="grid justify-between grid-cols-1 px-3 py-4 my-4 bg-white rounded-lg dark:bg-clrDarkBg md:flex md:flex-row md:px-6 md:pt-6 md:pb-3">
        <div>
          <h3 className="mb-1 text-xs font-semibold md:text-sm text-clrGray dark:text-clrPlaceholder md:mb-2">
            YOUR REFERRAL CODE
          </h3>
          <h1 className="text-[#25346A] dark:text-white mb-3 font-bold md:font-extrabold text-3xl md:mb-5">
            {refCode}
          </h1>
          <p className="mb-2 text-x10 md:text-xs text-clrGray dark:text-clrPlaceholder">
            Copy your referral code and share with friends to earn more Wicrypt
            Credit (WC) everytime your <br className="hidden xl:block" />
            friend performs a transaction on their Wicrypt App.
          </p>
        </div>
        <div
          className="flex justify-between items-center w-fit h-7 mt-3 px-4 gap-3 relative
                 bg-[#cfa80917] cursor-pointer rounded-[28px] transition duration-500 ease-in-out transform hover:scale-[0.95] border-[1px] border-solid border-clrYellow"
          onClick={() => {
            copyToClipboard(refCode);
            setCopied(refCode);
          }}
        >
          <CopyIcon width={15} height={15} />
          <p className="font-medium text-x11 text-clrDarkYellow whitespace-nowrap">
            Copy Code
          </p>
          {refCode === copiedText && (
            <p className="absolute right-0 p-1 mt-12 text-white border-solid rounded bg-clrYellow text-x10 ">
              {" "}
              Copied
            </p>
          )}
        </div>
      </div>
      <div className="px-3 py-4 bg-white rounded-lg dark:bg-clrDarkBg md:py-8">
        <div className=" md:pl-4">
          <h2 className="mb-5 text-xs font-semibold md:mb-6 md:text-sm text-clrTextGray dark:text-white">
            Your Referrals
          </h2>
        </div>
        <ReferralList />
      </div>
    </section>
  );
};

export default Referrals;
