import { apiSlice } from "./apiSlice";
import {
  AuthResponse,
  LoginDetails,
  EmailVerificationResponse,
  ResetRequest,
  SignUpDetails,
  GoogleLoginDetails,
  ResetRequestResponse,
  PasswordReset,
  ValidateEmailVerificationResponse,
  ValidateEmailVerification,
  PasswordResetResponse,
  ReferralRequest,
  ReferralResponse,
  BusinessProfileResponse,
  TwoFAResponse,
  TwoFAOptionResponse,
  UserProfileResponse,
  TwoFaVerifcationRequest,
  CountriesResponse,
  CountriesRequest,
  PasswordChange,
  TokenPayload,
} from "./authSliceTypes";


const authSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({

    //Login a user
    logIn: build.mutation<AuthResponse, Partial<LoginDetails>>({
      query: (payload) => ({
        url: '/v4/auth/sign-in',
        method: "POST",
        body: payload,
        headers: {
          'X-Tenant': process.env.REACT_APP_X_TENANT,
        },

      }),
    }),


    //Login a user with 2fa
    logInTwoFa: build.mutation<AuthResponse, Partial<LoginDetails>>({
      query: (payload) => ({
        url: '/v4/auth/sign-in/2fa',
        method: 'POST',
        body: payload,
        headers: {
          'X-Tenant': process.env.REACT_APP_X_TENANT,
        },

      }),
    }),

    //Generate Email Verification OTP
    generateEmailOTP: build.mutation<EmailVerificationResponse, string>({
      query: (email) => ({
        url: `/v4/two-factor-authentication/email/signup-code`,
        method: 'POST',
        body: { email },
        headers: {
          'X-Tenant': process.env.REACT_APP_X_TENANT,
        },
      }),
    }),

    //Verify Email Verification OTP
    verifyEmailOTP: build.mutation<
      ValidateEmailVerificationResponse,
      Partial<ValidateEmailVerification>
    >({
      query: (payload) => ({
        url: `/v2/OTP/Validate/Email`,
        method: "POST",
        body: payload
      }),
    }),

    //Create account for a user
    signUp: build.mutation<AuthResponse, Partial<SignUpDetails>>({
      query: (payload) => ({
        url: "/v4/auth/sign-up",
        method: "POST",
        body: payload,
        headers: {
          'X-Tenant': process.env.REACT_APP_X_TENANT,
        },
      }),
    }),

    //Login via Google
    logInWithGoogle: build.mutation<
      AuthResponse,
      Partial<GoogleLoginDetails>
    >({
      query: (payload) => ({
        url: `/v4/auth/${"Google"}/sign-In`,
        method: "POST",
        body: payload,
        headers: {
          'X-Tenant': process.env.REACT_APP_X_TENANT,
        },

      }),
    }),

    //Initialise Password Reset
    initialiseReset: build.mutation<
      ResetRequestResponse,
      Partial<ResetRequest>
    >({
      query: (payload) => ({
        url: `/v2/auth/password/forgot?email=${payload.email}`,
        method: "POST",
      }),
    }),

    //Reset Password
    resetPassword: build.mutation<PasswordResetResponse, Partial<PasswordReset>>({
      query: (payload) => ({
        url: `/v2/auth/password/reset`,
        method: "POST",
        body: payload,
      }),
    }),

    //Get User's referral list
    getReferrals: build.query<ReferralResponse, ReferralRequest>({
      query: ({ code, page, pageSize }) => `/v2/users/referrals/code/${code}?page=${page}&pageSize=${pageSize}`,
    }),

    //Get User's business 
    getBusinessProfile: build.query<BusinessProfileResponse, void>({
      query: () => `/v2/businesses/profile`,
    }),

    //Register a business
    registerBusiness: build.mutation<PasswordResetResponse, Partial<FormData>>({
      query: (payload) => ({
        url: `/v2/businesses`,
        method: "POST",
        body: payload,
      }),
    }),

    //Get Supported Countries
    getSupportedCountries: build.query<CountriesResponse, CountriesRequest>({
      query: ({ search, page, pageSize }) =>
        `/v4/countries/?page=${page}&pageSize=${pageSize}&search=${search}`,
    }),

    //Delete account
    deleteAccount: build.mutation<PasswordResetResponse, void>({
      query: () => ({
        url: `/v2/auth/deleteAccount`,
        method: "DELETE",
      }),
    }),

    //Get User's profile
    getUserProfile: build.query<UserProfileResponse, string>({
      query: (userId) => `/v2/users/${userId}/profile`,
    }),

    //setup 2fa
    twofaSetup: build.mutation<TwoFAResponse, void>({
      query: () => ({
        url: `/v4/two-factor-authentication/authenticator`,
        method: 'POST',
      }),
    }),

    //deactivate 2fa
    deactivate2fa: build.mutation<TwoFAResponse, void>({
      query: () => ({
        url: `/v4/two-factor-authentication`,
        method: 'DELETE',
      }),
      invalidatesTags: ['TwoFA'],
    }),

    //2fa Options
    twofaOptions: build.query<TwoFAOptionResponse, void>({
      query: () => ({
        url: `/v4/two-factor-authentication/option`,
      }),
      providesTags: ['TwoFA'],
    }),

    //verify 2fa
    verify2fa: build.mutation<TwoFAResponse, Partial<TwoFaVerifcationRequest>>({
      query: (payload) => ({
        url: `/v4/two-factor-authentication`,
        method: 'PUT',
        body: payload,
      }),
    }),

    //Generate Email Verification OTP For Existing User
    generateEmailOTPForExistingUser: build.mutation<
      EmailVerificationResponse,
      void
    >({
      query: () => ({
        url: `/v4/two-factor-authentication/email/code`,
        method: 'POST',
      }),
    }),

     //setup 2fa
     passwordChange: build.mutation<TwoFAResponse, PasswordChange>({
      query: (body) => ({
        url: `/v1/auth/password/change`,
        method: 'POST',
        body,
      }),
    }),

    // Refresh Token
    refreshToken: build.mutation<AuthResponse, TokenPayload>({
      query: (payload) => ({
        url: '/v4/auth/refresh-token',
        method: 'POST',
        body: payload,
      }),
    }),

  }),
  overrideExisting: true,
})

export const {
  useLogInMutation,
  useLogInWithGoogleMutation,
  useInitialiseResetMutation,
  useGenerateEmailOTPMutation,
  useVerifyEmailOTPMutation,
  useSignUpMutation,
  useResetPasswordMutation,
  useGetReferralsQuery,
  useGetBusinessProfileQuery,
  useGetSupportedCountriesQuery,
  useRegisterBusinessMutation,
  useDeleteAccountMutation,
  useDeactivate2faMutation,
  useGetUserProfileQuery,
  useTwofaOptionsQuery,
  useGenerateEmailOTPForExistingUserMutation,
  useVerify2faMutation,
  useTwofaSetupMutation,
  usePasswordChangeMutation,
  useLogInTwoFaMutation,
  useRefreshTokenMutation
} = authSlice;
