import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import ArrowDown from "../../assets/icons/arrow-down-Yellow.svg";
import { Button, ErrorMsg } from "../reusables";
import InfoIconGray from "../../assets/icons/wallet/info-circle-11.svg";
import AddIcon from "../../assets/icons/wallet/add.svg";
import { AddBankAccount } from "./P2PInfo";
import {
  useGetBanksQuery,
  useLazyGetAccountNameQuery,
  useSaveAccountDetailsMutation,
} from "../../features/api/walletSlice";

const AddBankDetails = () => {
  const navigate = useNavigate();

  const { userId } = useAppSelector((state) => state.auth);
  const [acctNumber, setAccountNumber] = useState<string>("");
  const [accountName, setAccountName] = useState<string>("");
  const [errorMsg, setError] = useState<string>("");
  const [accountSaveError, setAccountSaveError] = useState<string>("");
  const [selectedBank, selectBank] = useState<BankList>({
    id: 0,
    name: "",
    code: "000",
  });
  const [isAddBankAccount, setIsAddBankAccount] = useState<boolean>(false);
  const [isDropdown, toggleDropdown] = useState<boolean>(false);

  //Get list of banks that we have currently
  const { data: banks } = useGetBanksQuery();
  //Get account Name
  const [trigger, { isLoading: gettingAccountName }] =
    useLazyGetAccountNameQuery();
  //Save account details
  const [saveAccount, { data, isLoading: savingAccount }] =
    useSaveAccountDetailsMutation();

  //Function to validate form entries and save account number
  const handleAccountNumber = () => {
    if (!selectedBank.name) {
      setError("Please select a bank");
    } else if (!acctNumber) {
      setError("Please enter a valid account number");
    } else if (!accountName) {
      setError("Please enter a valid account name");
    } else {
      setError("")
      setIsAddBankAccount(true);
      // trigger({ acctNumber, bankId: selectedBank.id })
      //   .unwrap()
      //   .then((res) => {
      //     setError("");
      //     if (res.success) {
      //       setAccountName(res.data.accountName);
      //       setIsAddBankAccount(true);
      //     } else {
      //       setError(res.message);
      //     }
      //   })
      //   .catch((err) => setError(err.data.message));
    }
  };

  //Function to save the account details
  const handleSaveAccount = () => {
    let data = {
      accountNumber: acctNumber,
      bankId: selectedBank.id,
      accountName,
      userId,
    };
    saveAccount(data)
      .unwrap()
      .then((res) => {
        if (res.success) {
          navigate(-1);
        } else {
          setAccountSaveError(res.message);
        }
      })
      .catch((err) => setAccountSaveError(err.data.message));
  };

  return (
    <section className="bg-clrBg dark:bg-clrDarkBg2 w-[91%] max-w-[35rem] mx-auto pb-6 mt-5 overflow-y-auto">
      <div className="p-4 bg-white rounded dark:bg-clrDarkBg md:px-8 md:py-8">
        <div className="mb-3">
          <h2 className="mb-2 text-sm font-semibold text-black dark:font-medium md:text-base dark:text-white">
            Add Bank Details
          </h2>
          <p className="font-medium text-x10 text-clrGray dark:text-clrPlaceholder">
            Add a new new bank account
          </p>
        </div>

        <div className="mt-5 md:p-3 md:mt-0">
          <div className="flex items-center gap-2">
            <p className="md:text-[8px] md:font-normal text-x10 text-clrTextBlue font-semibold md:text-clrGray dark:text-white">
              Bank Institution{" "}
            </p>
            <img src={InfoIconGray} alt="" width={15} height={15} />
          </div>

          <div
            className="flex items-center gap-2 border-b-[1px] border-solid border-clrTextBlue dark:border-clrGray pt-2 pb-1 md:py-2 pr-2 md:mt-3 cursor-pointer"
            onClick={() => toggleDropdown(!isDropdown)}
          >
            <img
              src={AddIcon}
              alt="Add bank"
              width={20}
              height={20}
              className="hidden md:block"
            />

            <p className="text-x10 font-normal text-[#989898] mr-auto dark:text-clrPlaceholder">
              {selectedBank?.name ? selectedBank?.name : "Select bank"}
            </p>
            <img
              src={ArrowDown}
              alt="more options"
              width={20}
              height={20}
              className="w-4 h-4 md:h-5 md:w-5"
            />
          </div>
        </div>
        <div className="absolute overflow-auto h-2/5">
          {isDropdown && (
            <div className="p-3 space-y-2 bg-white border-2 rounded-md dark:bg-clrDarkBg border-clrBg">
              {banks?.data.map((bank) => {
                return (
                  <div
                    className="flex items-center justify-between p-2 cursor-pointer"
                    key={bank.id}
                    onClick={() => {
                      selectBank(bank);
                      toggleDropdown(!isDropdown);
                    }}
                  >
                    <p className="font-medium text-x10 text-clrTextBlue dark:text-clrPlaceholder">
                      {bank.name}
                    </p>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <div className="mt-5 md:p-3 md:mt-0">
          <label
            htmlFor=""
            className="block mb-1 font-semibold md:text-xs text-x10 text-clrTextBlue dark:text-white dark:font-medium"
          >
            Account name
          </label>
          <input
            type="text"
            placeholder="Enter account name"
            onChange={(e) => setAccountName(e.target.value)}
            value={accountName}
            className="placeholder:text-x10 text-x10 w-full dark:text-clrPlaceholder md:border-[#C4C4C4] dark:border-clrGray dark:bg-clrDarkBg border-clrTextBlue border-solid border-b-[1px] pb-1 focus:outline-none"
          />
        </div>
        <div className="mt-5 md:p-3 md:mt-0">
          <label
            htmlFor=""
            className="block mb-1 font-semibold md:text-xs text-x10 text-clrTextBlue dark:text-white dark:font-medium"
          >
            Account number
          </label>
          <input
            type="text"
            placeholder="Enter account number"
            onChange={(e) => setAccountNumber(e.target.value)}
            className="placeholder:text-x10 text-x10 w-full dark:text-clrPlaceholder md:border-[#C4C4C4] dark:border-clrGray dark:bg-clrDarkBg border-clrTextBlue border-solid border-b-[1px] pb-1 focus:outline-none"
          />
        </div>
        <ErrorMsg errMsg={errorMsg} />
        <div className="md:w-[50%] my-12 mx-auto ">
          <Button
            text="Continue"
            type="button"
            loading={gettingAccountName}
            handleClick={handleAccountNumber}
          />
        </div>
      </div>
      {isAddBankAccount && (
        <AddBankAccount
          handleCloseModal={() => setIsAddBankAccount(false)}
          handleSave={handleSaveAccount}
          loading={savingAccount}
          accountName={accountName}
          accountNumber={acctNumber}
          bankName={selectedBank?.name}
          errMsg={accountSaveError}
        />
      )}
    </section>
  );
};

export default AddBankDetails;
