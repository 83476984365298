import React, { useRef, useState } from "react";
import ArrowYellow from "../../assets/icons/arrow-down-Yellow.svg";
import { ReactComponent as Phone2 } from "../../assets/icons/phone.svg";
import BearIcon from "../../assets/icons/bear.svg";
import LogOutIcon from "../../assets/icons/logout-1.svg";
import WalletConnectIcon from "../../assets/icons/wallet/wallet-connect.svg";
import Profile from "../../assets/profile-pic.svg";
import CustomerSupport from "../home/CustomerSupport";
import TopRightMenu from "../home/TopRightMenu";
import { ReactComponent as Menu2 } from "../../assets/icons/menu.svg";
import { useLocation, useNavigate, useParams } from "react-router";
import { ReactComponent as ArrowBack } from "../../assets/icons/arrow-back.svg";
import useOutsideClick from "../../Hooks/useOutsideClick";
import { ButtonOutlineWallet } from "../reusables";
import { ExplorerChooseWallet } from "../explorer/ChooseWallet";
import { useAppSelector } from "../../app/hooks";
import { useGetHotspothubstatisticsQuery } from "../../features/api/explorerSlice";
import RouterLogin from "../routerLogin";
import { useGetBusinessProfileQuery } from "../../features/api/authSlice";
import ToggleVersion from "../reusables/ToggleWicryptVersion";

interface NotifyProps {
  handleMenu: () => void;
}

const Notify: React.FC<NotifyProps> = ({ handleMenu }) => {
  const [showSupport, setShowSupport] = useState(false);
  const [showProfile, setShowProfile] = useState(false);

  const { agentName, businessUserId, businessId, sessionId, cycleId, tokenId } =
    useParams();

  const { connectWalletDetails, appModal } = useAppSelector(
    (state) => state.auth
  );

  const modalRef = useRef<HTMLElement>(null);
  const modalRefProfile = useRef<HTMLElement>(null);

  const location = useLocation();

  const handleCloseModal = () => {
    setShowSupport(false);
    setShowProfile(false);
  };

  useOutsideClick(modalRef, handleCloseModal);
  useOutsideClick(modalRefProfile, handleCloseModal);

  //Get the hotspothub statistics
  const { data: hubDetail } = useGetHotspothubstatisticsQuery({
    tokenId: tokenId as string,
    lookbackPeriodInHours: "",
    chartLookbackPeriodInDays: "",
  }, {skip: !tokenId});

  const titleMap: { [key: string]: string } = {
    "/home/dashboard": "Dashboard",
    "/home/dashboard/business-reg": "Business Registration",
    "/home/hotspot-hubs": "Hotspot Hubs",
    "/home/wallet": "Wallet",
    "/home/hotspot-hubs/my-hubs": "Hotspot Hubs",
    "/home/hotspot-hubs/live-session": "Hotspot Hubs",
    "/home/hotspot-hubs/hubs-report": "Hotspot Hubs",
    "/home/hotspot-hubs/device-tracker": "Track Data",
    "/home/hotspot-hubs/session-history": "Session History",
    "/home/wallet/transactionHistory": "Transaction History",
    "/home/wallet/peer-to-peer": "Peer to Peer(P2P)",
    "/home/wallet/withdraw-P2P": "Peer to Peer(P2P)",
    "/home/wallet/crypto-withdraw": "Crypto Withdrawal",
    "/home/wallet/add-bank-details": "Add Bank Details",
    "/home/wallet/stake": "Stake",
    "/home/wallet/explorer": "My Explorer",
    "/home/explorer/overview": "My Explorer",
    "/home/explorer/explorer-devices": "My Explorer",
    "/home/explorer/claim-history": "My Explorer",
    "/home/explorer/hotspot-hubs": "My Explorer",
    "/home/booster/overview": "Reward Booster",
    "/home/booster/history": "Reward Booster",
    "/home/booster/booster-faq": "Booster FAQ’S",
    "/home/booster/history/:cycleId": "Reward History",
    "/home/explorer/devices-name/:tokenId/statistics": "Hub Name",
    "/home/explorer/devices-name/:tokenId/transactions": "Hub Name",
    "/home/profile": "Profile",
    "/home/profile/verifyID": "Verify Identity",
    "/home/token": "Token",
    "/home/vouchers": "Voucher",
    "/home/agents": "Agents",
    "/home/referrals": "Referrals",
  };

  const defaultTitle = "Dashboard";
  //const title = titleMap[location.pathname] || defaultTitle;

  // Regular expression to match dynamic route parameters
  const agentRoutePattern = /^\/home\/agents\/[\w-/%]+$/;
  const sessionRoutePattern = /^\/home\/explorer\/session-name\/[\w-/%]+$/;
  const boostRoutePattern = /^\/home\/booster\/history\/[\w-/%]+$/;
  const statisticsRoutePattern =
    /^\/home\/explorer\/devices-name\/[\w-/%]+\/(?:statistics|transactions)$/;

  // Function to get the title based on the route
  const getTitleFromRoute = (route: string): string => {
    if (titleMap[route]) {
      return titleMap[route];
    } else if (agentRoutePattern.test(route)) {
      return agentName as string; // Or any other dynamic title you want for the matched pattern
    } else if (sessionRoutePattern.test(route)) {
      return sessionId?.slice(0, 4) + "..." + sessionId?.slice(-4);
    } else if (boostRoutePattern.test(route)) {
      return "Boost Reward";
    } else if (statisticsRoutePattern.test(route)) {
      return hubDetail?.data?.deviceName || "Hub Name";
    }
    return defaultTitle;
  };

  let title = getTitleFromRoute(location.pathname);

  const isSpecificPath =
    location.pathname === "/home/hotspot-hubs/session-history" ||
    location.pathname === "/home/hotspot-hubs/device-tracker" ||
    location.pathname === "/home/wallet/transactionHistory" ||
    location.pathname === "/home/wallet/peer-to-peer" ||
    location.pathname === "/home/wallet/withdraw-P2P" ||
    location.pathname === "/home/wallet/crypto-withdraw" ||
    location.pathname === "/home/wallet/add-bank-details" ||
    location.pathname === "/home/wallet/stake" ||
    decodeURI(location.pathname) ===
      `/home/explorer/session-name/${sessionId}` ||
    decodeURI(location.pathname) === `/home/booster/history/${cycleId}` ||
    decodeURI(location.pathname) ===
      `/home/explorer/devices-name/${tokenId}/statistics` ||
    decodeURI(location.pathname) ===
      `/home/explorer/devices-name/${tokenId}/transactions` ||
    location.pathname === "/home/booster/booster-faq" ||
    location.pathname === "/home/profile/verifyID" ||
    location.pathname === "/home/dashboard/business-reg" ||
    decodeURI(location.pathname) ===
      `/home/agents/${businessUserId}/${agentName}/${businessId}`;

  const navigate = useNavigate();
  const handleBack = () => {
    const pathname = decodeURI(location.pathname);

    if (pathname === "/home/hotspot-hubs/session-history") {
      navigate("/home/hotspot-hubs/live-session");
    } else if (pathname === "/home/hotspot-hubs/device-tracker") {
      navigate("/home/hotspot-hubs");
    } else if (
      pathname === "/home/wallet/transactionHistory" ||
      pathname === "/home/wallet/peer-to-peer" ||
      pathname === "/home/wallet/withdraw-P2P" ||
      pathname === "/home/wallet/crypto-withdraw" ||
      pathname === "/home/wallet/stake"
    ) {
      navigate("/home/wallet");
    } else if (pathname === "/home/wallet/add-bank-details") {
      navigate("/home/wallet/peer-to-peer");
    } else if (pathname === "/home/booster/booster-faq") {
      navigate("/home/booster");
    } else if (
      pathname === `/home/explorer/devices-name/${tokenId}/statistics` ||
      pathname === `/home/explorer/devices-name/${tokenId}/transactions`
    ) {
      navigate("/home/explorer/hotspot-hubs");
    } else if (pathname === `/home/explorer/session-name/${sessionId}`) {
      navigate(`/home/explorer/devices-name/${tokenId}/transactions`);
    } else if (pathname === "/home/profile/verifyID") {
      navigate("/home/profile");
    } else if (pathname === "/home/dashboard/business-reg") {
      navigate("/home/dashboard");
    } else if (
      pathname === `/home/agents/${businessUserId}/${agentName}/${businessId}`
    ) {
      navigate("/home/agents");
    } else if (pathname === `/home/booster/history/${cycleId}`) {
      navigate("/home/booster/history");
    }
  };

  // to open the modal in explorer screen
  const [chooseWallet, setChooseWallet] = useState(false);

  const handleModal = () => {
    setChooseWallet(false);
  };

  const {
    data: businessProfile,
  } = useGetBusinessProfileQuery();

  return (
    <div className="flex items-center p-5 bg-white dark:bg-clrDarkBg md:px-10 md:py-6">
      <div className="flex gap-4 mr-auto">
        <button onClick={handleMenu} className="block lg:hidden">
          <Menu2 className="dark-menu" />
        </button>
        <div className="flex items-center gap-2">
          {isSpecificPath && (
            <button className="btn-reset" onClick={() => navigate(-1)}>
              <ArrowBack className="w-5 h-5 dark-arrow" />
            </button>
          )}
          <h1 className="text-sm font-semibold md:text-base text-clrTitle dark:text-white">
            {title}
          </h1>
        </div>
      </div>
      <div className="items-center hidden gap-4 md:flex">
        <ToggleVersion />
        {location.pathname.includes("explorer") && (
          <>
            {!connectWalletDetails?.walletAddress ? ( // Show ButtonOutlineWallet if choice is not made
              <ButtonOutlineWallet
                handleClick={() => setChooseWallet(true)}
                text="Connect Wallet"
                type="button"
              />
            ) : (
              // Show custom button if choice is made
              <button
                onClick={() => setChooseWallet(true)}
                className="flex gap-2 justify-between items-center py-1.5 px-4 bg-clrYellow2 bg-opacity-10 rounded-[22px]"
              >
                <img
                  src={
                    connectWalletDetails?.walletName === "Metamask"
                      ? BearIcon
                      : WalletConnectIcon
                  }
                  alt="wallet-logo"
                  width={15}
                  height={15}
                />
                <p className="font-semibold text-x9 text-clrTextGray">
                  {connectWalletDetails?.walletAddress.slice(0, 4) +
                    "..." +
                    connectWalletDetails?.walletAddress.slice(-4)}
                </p>
                <img src={LogOutIcon} alt="copy now" width={12} height={12} />
              </button>
            )}
            {chooseWallet && (
              <ExplorerChooseWallet handleCloseModal={handleModal} />
            )}
          </>
        )}
        <div className="relative">
          <button
            className="flex items-center gap-2 px-2 py-1 rounded-lg h-fit bg-clrBtnGray dark:bg-clrDarkBg2"
            onClick={() => setShowSupport(!showSupport)}
          >
            {/* <img src={Phone} alt="Call" width={15} height={15} /> */}
            <Phone2 className="w-[0.9375rem] h-[0.9375rem] dark-phone" />
            <p className="text-[0.625rem] font-normal text-[#515151] dark:text-clrGray tracking-[0.01em]">
              Support
            </p>
            <img src={ArrowYellow} alt="Arrow down" width={20} height={20} />
          </button>
          {showSupport && <CustomerSupport modalRef={modalRef} />}
        </div>
        <img
          src={ businessProfile?.data?.profileUrl ? businessProfile?.data?.profileUrl : Profile}
          alt="Profile"
          width={40}
          height={40}
          className="w-10 rounded-full pic-aspect"
        />
        <div className="relative">
          <button
            onClick={() => setShowProfile(!showProfile)}
            className="mt-[5px]"
          >
            <img src={ArrowYellow} alt="Arrow down" width={20} height={20} />
          </button>
          {showProfile && <TopRightMenu modalRef={modalRefProfile} />}
        </div>
      </div>
      {appModal?.id === "routerCode" && <RouterLogin />}
    </div>
  );
};

export default Notify;
