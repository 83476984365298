
const ToggleVersion = () => {
  const handleDomainChange = () => {
    const currentPath = window.location.pathname;
    const newDomain = "https://dashboard-alpha.wicrypt.com";
    window.location.href = `${newDomain}${currentPath}`;
  };

  return (
    <div id="step-12" className="flex items-center gap-2">
      <label className="wicrypt_switch">
        <p className="hidden">Toggle</p>
        <input
          type="checkbox"
          id="toggle-wicrypt"
          onChange={handleDomainChange}
        />
        <span className="wicrypt_switch__toggle"></span>
      </label>
      <label
        htmlFor="toggle-mode"
        className="text-xs font-medium text-clrDarkYellow cursor-pointer"
      >
        Switch to New Interface
      </label>
    </div>
  );
};

export default ToggleVersion;