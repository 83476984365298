import React, { useRef, useState } from "react";
import ArrowYellow from "../../assets/icons/arrow-down-Yellow.svg";
import { ReactComponent as Phone2 } from "../../assets/icons/phone.svg";
import BearIcon from "../../assets/icons/bear.svg";
import LogOutIcon from "../../assets/icons/logout-1.svg";
import WalletConnectIcon from "../../assets/icons/wallet/wallet-connect.svg";
import Profile from "../../assets/profile-pic.svg";
import CustomerSupport from "../home/CustomerSupport";
import TopRightMenu from "../home/TopRightMenu";
import { useLocation } from "react-router";
import useOutsideClick from "../../Hooks/useOutsideClick";
import { ButtonOutlineWallet } from "../reusables";
import { ExplorerChooseWallet } from "../explorer/ChooseWallet";
import { useAppSelector } from "../../app/hooks";
import { useGetBusinessProfileQuery } from "../../features/api/authSlice";

const NotifyMobile: React.FC = () => {
  const [showSupport, setShowSupport] = useState(false);
  const [showProfile, setShowProfile] = useState(false);

  const {
    data: businessProfile,
  } = useGetBusinessProfileQuery();
  
  const { connectWalletDetails } = useAppSelector(
    (state) => state.auth
  );

  const modalRef = useRef<HTMLElement>(null);
  const modalRefProfile = useRef<HTMLElement>(null);

  const location = useLocation();

  const handleCloseModal = () => {
    setShowSupport(false);
    setShowProfile(false);
  };

  useOutsideClick(modalRef, handleCloseModal);
  useOutsideClick(modalRefProfile, handleCloseModal);

  // to open the modal in explorer screen
  const [chooseWallet, setChooseWallet] = useState(false);

  const handleModal = () => {
    setChooseWallet(false);
  };

  return (
    <div className="flex items-center mt-5 bg-white md:hidden bottom-3 dark:bg-clrDarkBg">
      <div className="flex flex-wrap items-center gap-4">
        {location.pathname.includes("explorer") && (
          <>
            {!connectWalletDetails?.walletAddress ? ( // Show ButtonOutlineWallet if choice is not made
              <ButtonOutlineWallet
                handleClick={() => setChooseWallet(true)}
                text="Connect Wallet"
                type="button"
              />
            ) : (
              // Show custom button if choice is made
              <button
                onClick={() => setChooseWallet(true)}
                className="flex gap-2 justify-between items-center py-1.5 px-4 bg-clrYellow2 bg-opacity-10 rounded-[22px]"
              >
                <img
                  src={
                    connectWalletDetails?.walletName === "Metamask"
                      ? BearIcon
                      : WalletConnectIcon
                  }
                  alt="wallet-logo"
                  width={15}
                  height={15}
                />
                <p className="font-semibold text-x9 text-clrTextGray">
                  {connectWalletDetails?.walletAddress.slice(0, 4) +
                    "..." +
                    connectWalletDetails?.walletAddress.slice(-4)}
                </p>
                <img src={LogOutIcon} alt="copy now" width={12} height={12} />
              </button>
            )}
            {chooseWallet && (
              <ExplorerChooseWallet handleCloseModal={handleModal} />
            )}
          </>
        )}
        <div className="relative">
          <button
            className="flex items-center gap-2 px-2 py-1 rounded-lg h-fit bg-clrBtnGray dark:bg-clrDarkBg2"
            onClick={() => setShowSupport(!showSupport)}
          >
            <Phone2 className="w-[0.9375rem] h-[0.9375rem] dark-phone" />
            <p className="text-[0.625rem] font-normal text-[#515151] dark:text-clrGray tracking-[0.01em]">
              Support
            </p>
            <img
              src={ArrowYellow}
              alt="Arrow down"
              width={20}
              height={20}
              className="rotate-180"
            />
          </button>
          {showSupport && (
            <div>
              <CustomerSupport modalRef={modalRef} />
            </div>
          )}
        </div>
        <img
          src={businessProfile?.data?.profileUrl ? businessProfile?.data?.profileUrl: Profile}
          alt="Profile"
          width={40}
          height={40}
          className="w-10 rounded-full pic-aspect"
        />
        <div className="relative">
          <button onClick={() => setShowProfile(!showProfile)}>
            <img
              src={ArrowYellow}
              alt="Arrow down"
              width={20}
              height={20}
              className="rotate-180"
            />
          </button>
          {showProfile && <TopRightMenu modalRef={modalRefProfile} />}
        </div>
      </div>
    </div>
  );
};

export default NotifyMobile;
