import { useEffect, useState } from "react";
import { base32 } from "rfc4648";
import { Modal } from "../reusables/Modal";
import { ReactComponent as CopyIcon } from "../../assets/icons/copy.svg";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { copyToClipboard } from "../../Helper";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { toggleAppModal } from "../../features/store/authReducerSlice";

const totp = require("totp-generator");
const { TextEncoder } = require('text-encoding');


const RouterLogin = () => {
    const dispatch = useAppDispatch();
    const [timedToken, setToken] = useState<string>("");
    const [remainingTime, setRemainingTime] = useState<number>(0);
    const { emailAddress: email } = useAppSelector((state) => state.auth);
    const [copiedText, setCopied] = useState<string>("");


    useEffect(() => {
        if ( email) {
            let textToencode = `${email}${process.env.REACT_APP_ENCRYPTION_CODE}`
            // Create a TextEncoder instance
            const textEncoder = new TextEncoder();

            // Convert the string to a byte array (Uint8Array)
            const byteArray = textEncoder.encode(textToencode);

            const encoded = base32.stringify(byteArray);
            const token = totp(encoded, {
                digits: 6,
                period: 300,
                timestamp: new Date().getTime(),
            });
            setToken(token);
            
        }
        let time = 300 - Math.round((new Date().getTime()/1000)%300);
        const interval = setInterval(() => {
            setRemainingTime(time)
        }, 1000);
        return () => {
            clearInterval(interval);
        };
    }, [remainingTime, email]);

    

    const formatTime = (timeInSeconds: number) => {
        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = timeInSeconds % 60;
        return `${minutes}m ${seconds}s`;
    };
    
      

    return (
        <Modal
            close={true}
            title={"Hub Code"}
            description={""}
            handleCloseModal={() => dispatch(toggleAppModal({ id: "", detail: "" }))}
        >
            <section>
                <div className="flex flex-col items-center justify-center gap-3 px-8 py-8 bg-white rounded dark:bg-clrDarkBg text-center">
                    <p className="text-x9 md:text-x10 font-medium text-clrGray66 dark:text-clrPlaceholder mx-auto"> This code refreshes every 5 minutes. </p>

                    <p className="text-x9 md:text-x10 font-medium text-clrGray66 dark:text-clrPlaceholder mx-auto mb-3">
                        Copy the email and code above and <br />
                        use it to login on the hub's sign-in page by opening your browser </p>
                    <div style={{ width: 80, height: 80 }} className="mb-5">
                        <CircularProgressbar
                            maxValue={300}
                            minValue={0}
                            strokeWidth={8}
                            value={remainingTime}
                            text={formatTime(remainingTime)}
                            counterClockwise={true}
                            styles={{
                                // Customize the path, i.e. the "completed progress"
                                path: {
                                    // Path color
                                    stroke: `#25346A`,
                                },
                                // Customize the circle behind the path, i.e. the "total progress"
                                trail: {
                                    // Trail color
                                    stroke: '#CFA809',
                                },
                                // Customize the text
                                text: {
                                    // Text color
                                    fill: '#6B6B6B',
                                    // Text size
                                    fontSize: '16px',
                                },

                            }}
                        />
                    </div>
                    <div className="w-full">

                        <div className="relative flex justify-between border-b-[1px] pb-5">
                            <span className="text-xs font-medium md:text-sm dark:text-white">
                                {email}
                            </span>
                            <CopyIcon className="cursor-pointer" onClick={() => { copyToClipboard(email); setCopied(email) }} />

                            {email === copiedText && (
                                <p className="absolute right-0 p-1 mt-4 text-white border-solid rounded bg-clrYellow text-x10 ">
                                    {" "}
                                    Copied
                                </p>
                            )}
                        </div>
                        <div className="relative flex justify-between  mt-5">
                            <span className="text-sm font-medium md:text-lg dark:text-white">
                                {timedToken}
                            </span>
                            <CopyIcon className="cursor-pointer" onClick={() => { copyToClipboard(timedToken); setCopied(timedToken) }} />

                            {timedToken === copiedText && (
                                <p className="absolute right-0 p-1 mt-4 text-white border-solid rounded bg-clrYellow text-x10 ">
                                    {" "}
                                    Copied
                                </p>
                            )}
                        </div>
                    </div>

                </div>
            </section>

        </Modal>

    )
}

export default RouterLogin