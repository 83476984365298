import { useAppSelector } from "../../app/hooks";
import SwitchSwap from "../../assets/icons/wallet/switch-swap.svg";
import { Button, TableLoader } from "../reusables";
import { useEffect, useState } from "react";
import { ReactComponent as SuccessIcon } from "../../assets/icons/wallet/success.svg";
import {
  useCheckPendingOrderQuery,
  useGetConversionRateQuery,
  useGetTransactionChargeQuery,
  useInitiateOrderMutation,
  useLazyGetTransactionChargeQuery,
  useLazySendP2POTPQuery,
} from "../../features/api/walletSlice";
import { numberWithCommas, numberWithCommasWithoutDecimal } from "../../Helper";
import { ErrorMsg } from "../reusables";
import { Modal } from "../reusables/Modal";
import { WithdrawVerifyEmail } from "./WithdrawInfo";

export const Swap: React.FC<SwapProps> = ({
  closeModal,
  handleModal,
  swapStep,
  balances,
  selectedCurrency,
}) => {
  const { userId } = useAppSelector((state) => state.auth);
  const [otp, setOtp] = useState<string>("");
  const [swapDestinations, setSwapDestinations] = useState<DestinationProps>({
    send: {
      symbol: "",
      name: "",
      iconUrlSvg: "",
      iconUrlPng: "",
      balance: 0,
      usdBalances: 0,
      currencyId: 0,
    },
    receive: {
      symbol: "",
      name: "",
      iconUrlSvg: "",
      iconUrlPng: "",
      balance: 0,
      usdBalances: 0,
      currencyId: 0,
    },
  });
  const [amtToSwap, setAmtToSwap] = useState<string>("");
  const [pendingSwap, setPendingSwap] = useState<boolean>(false);
  const [trigger] = useLazySendP2POTPQuery();
  const [errMsg, setErr] = useState<string>("");

  useEffect(() => {
    if (balances) {
      let send;
      let receive;
      balances.map((each) => {
        if (each.currencyId === selectedCurrency.id) {
          send = each;
        } else {
          receive = each;
        }
      });
      if (send && receive) {
        setSwapDestinations({ send: send, receive: receive });
      }
    }
  }, []);

  const { data: rate } = useGetConversionRateQuery({
    inboundCurrencyId: swapDestinations.receive.currencyId,
    outBoundCurrencyId: swapDestinations.send.currencyId,
  }, {
    skip: !swapDestinations.receive.currencyId || !swapDestinations.send.currencyId,
  });

  const { data: pendingOrder, isLoading: loadingPendingOrder } =
    useCheckPendingOrderQuery(userId);

  useEffect(() => {
    if (pendingOrder?.data.orderStatus === "ProcessingRequest") {
      setPendingSwap(true);
    }
  }, [pendingOrder]);


  //If user confirms, send OTP
  const handleSendOTP = () => {
    trigger({
      currencyId: swapDestinations.send.currencyId,
      channel: "XendBridge",
    });
  };
  const handleOtp = (res: string) => {
    setOtp(res);
  };

  const handleOTPVerificaton = () => {
    if (otp.length !== 6) {
      setErr("Please enter the OTP that was sent to your email account");
    } else {
      handleModal(3)
    }
  }

  const renderSwapStep = () => {
    switch (swapStep) {
      case 1:
        return (
          <SwapAmount
            closeModal={closeModal}
            handleModal={handleModal}
            balances={balances}
            selectedCurrency={selectedCurrency}
            swapDestinations={swapDestinations}
            rate={rate?.data.rate as number}
            setAmtToSwap={setAmtToSwap}
            amtToSwap={amtToSwap}
            sendOTP={handleSendOTP}
          />
        );
      case 2:
        return (
          <WithdrawVerifyEmail
            handleModal={closeModal}
            handleContinue={handleOTPVerificaton}
            handleChange={handleOtp}
            errMsg={errMsg}
            resendOtp={handleSendOTP}
            loading={false}
          />
        )
      case 3:
        return (
          <SwapDetails
            handleModal={handleModal}
            closeModal={closeModal}
            rate={rate}
            swapDestinations={swapDestinations}
            amtToSwap={Number(amtToSwap)}
            otp={otp}
          />
        );
      case 4:
        return (
          <SwapStatus
            handleModal={handleModal}
            rate={rate?.data.rate}
            swapDestinations={swapDestinations}
            amtToSwap={Number(amtToSwap)}
            closeModal={closeModal}
          />
        );
      default:
        return;
    }
  };

  return (
    <>
      {loadingPendingOrder ? (
        <TableLoader />
      ) : pendingSwap ? (
        <PendingSwap
          handleModal={handleModal}
          rate={rate?.data.rate}
          swapDestinations={swapDestinations}
          amtToSwap={Number(amtToSwap)}
          closeModal={closeModal}
        />
      ) : (
        renderSwapStep()
      )}
    </>
  );
};

//Swap Amount
export const SwapAmount: React.FC<SwapAmount> = ({
  closeModal,
  handleModal,
  swapDestinations,
  rate,
  setAmtToSwap,
  amtToSwap,
  sendOTP
}) => {
  const [isError, setError] = useState("");

  const [trigger] = useLazyGetTransactionChargeQuery();
  const [transactionCharge, setTransactionCharge] = useState<number>(0);
  const [chargeableAmount, setChargeableAmount] = useState<number>(0);
  const handleSubmit = () => {
    if (Number(amtToSwap) === 0) {
      setError("Please enter a valid amount");
    } else if (Number(amtToSwap) > swapDestinations?.send.balance) {
      setError("You cannot swap more than you have");
    } else {
      handleModal(2);
      sendOTP();
      setError("");
    }
  };

  useEffect(() => {
    if (amtToSwap) {
      let data = {
        transactionCategory: "Swap",
        currencyId: swapDestinations?.send.currencyId,
        channel: "XendBridge",
        amount: Number(amtToSwap),
      }
      trigger(data)
        .unwrap()
        .then((res) => {
          if (res.success) {
            setTransactionCharge(res.data.transactionCharge)
            setChargeableAmount(res.data.chargeableAmount)
          }
        });
    }

  }, [amtToSwap, swapDestinations])

  const handleMax = () =>{
    let data = {
      transactionCategory: "Swap",
      currencyId: swapDestinations?.send.currencyId,
      channel: "XendBridge",
      amount: swapDestinations?.send.balance,
    }
    trigger(data)
      .unwrap()
      .then((res) => {
        if (res.success) {
          let transactionCharge = res?.data.transactionCharge;
          let max = (swapDestinations?.send.balance || 0) - (transactionCharge || 0)
          setAmtToSwap(max.toString())
        }
      });
  }

  return (
    <Modal
      close={true}
      title={"Swap"}
      description={"Enter Amount"}
      handleCloseModal={closeModal}
      handleGoBack={() => handleModal(0)}
      goBack={true}
    >
      <>
        <div className="gap-3 px-3 py-8 space-y-5 bg-white rounded dark:bg-clrDarkBg ">
          <div className="p-2 rounded-lg md:px-4 md:py-2 bg-clrBg2 dark:bg-clrDarkBg2">
            <h3 className="mb-2 text-xs font-semibold text-left text-black dark:font-medium dark:text-white md:text-sm">
              From
            </h3>
            <div className="flex items-center gap-2">
              <input
                value={amtToSwap}
                type="number"
                className="md:mr-auto text-xs font-medium text-clrGray dark:text-clrPlaceholder dark:placeholder:!text-clrPlaceholder bg-inherit outline-none w-[60%]"
                onChange={(e) => setAmtToSwap(e.target.value)}
              />
              <p
                onClick={() =>
                  handleMax()
                }
                className="text-[10px] cursor-pointer font-semibold text-clrTextGray dark:text-clrPlaceholder bg-white dark:bg-clrDarkBg px-1 border-[1px] border-solid border-clrBorder2 rounded-md"
              >
                Max
              </p>

              <div className="w-5 h-5 md:w-[28px] md:h-[28px] rounded-[50%] bg-white dark:bg-clrDarkBg flex justify-center items-center">
                <img
                  src={swapDestinations?.send.iconUrlSvg}
                  alt="WNT"
                  width={20}
                  height={20}
                  className="w-3 h-3 md:w-5 md:h-5"
                />
              </div>
              <p className="text-xs font-semibold md:text-sm text-clrTextGray dark:text-clrPlaceholder dark:font-medium ">
                {swapDestinations?.send?.symbol}
              </p>
            </div>
          </div>

          <div className="grid place-content-center">
            <img src={SwitchSwap} alt="more options" width={20} height={20} />
          </div>

          <div className="p-2 rounded-lg md:px-4 md:py-2 bg-clrBg2 dark:bg-clrDarkBg2">
            <h3 className="mb-2 text-xs font-semibold text-left text-black dark:font-medium dark:text-white md:text-sm">
              To
            </h3>
            <div className="flex items-center justify-between gap-2">
              <p className="text-clrGray md:mr-auto text-xs font-medium w-[60%] dark:text-clrPlaceholder">
                {rate && numberWithCommas(Number(amtToSwap) * (rate || 0), 4)}
              </p>
              <div className="flex items-center gap-2">
                <div className="w-5 h-5 md:w-[28px] md:h-[28px] rounded-[50%] bg-white dark:bg-clrDarkBg flex justify-center items-center">
                  <img
                    src={swapDestinations?.receive.iconUrlSvg}
                    alt={swapDestinations?.receive.symbol}
                    width={20}
                    height={20}
                    className="w-3 h-3 md:w-5 md:h-5"
                  />
                </div>
                <p className="text-xs font-semibold dark:font-medium md:text-sm text-clrTextGray dark:text-clrPlaceholder">
                  {swapDestinations?.receive.symbol}
                </p>
              </div>
            </div>


          </div>
          <div className="mt-4 md:px-3">
            <p className="font-normal text-x8 text-clrGray dark:text-clrPlaceholder mb-1">
              Available balance:{" "}
              <span className="text-[#E5B910]">
                {" "}
                {numberWithCommasWithoutDecimal(swapDestinations?.send.balance || 0)}{" "}
                {swapDestinations?.send.symbol}

              </span>
            </p>
            <p className="font-normal text-x8 text-clrRed dark:text-clrPlaceholder mb-1">
              Transaction Charge:{" "}
              <span className="text-clrRed">
                {" "}
                {transactionCharge || 0}{" "}
                {swapDestinations?.send.symbol}

              </span>
            </p>

            <p className="font-normal text-x8 text-clrGray dark:text-clrPlaceholder mt-1">
              Total Chargeable Amount:{" "}
              <span className="text-[#E5B910]">
                {" "}
                {chargeableAmount}{" "}
                {swapDestinations?.send.symbol}

              </span>
            </p>
            {(chargeableAmount > swapDestinations?.send?.balance) && (Number(amtToSwap) !== 0) &&
              <ErrorMsg errMsg={"*Total Chargeable amount cannot be greater than your available balance"} />}
            <ErrorMsg errMsg={isError} />
          </div>
        </div>

        <div className="md:w-[80%] mt-6 mx-auto ">
          <Button
            type="button"
            text="Swap"
            handleClick={handleSubmit}
            disabled={chargeableAmount > swapDestinations?.send?.balance || (Number(amtToSwap) === 0)}
          />
        </div>
      </>
    </Modal>
  );
};

export const SwapDetails: React.FC<SwapDetailProps> = ({
  handleModal,
  closeModal,
  rate,
  swapDestinations,
  amtToSwap,
  otp
}) => {
  const { userId } = useAppSelector((state) => state.auth);
  const [errMsg, setError] = useState("");

  const { data: transactionCharge, isLoading: gettingCharge } =
    useGetTransactionChargeQuery({
      transactionCategory: "Swap",
      currencyId: swapDestinations?.send.currencyId,
      channel: "XendBridge",
      amount: amtToSwap,
    });
  const [initateOrder, { isLoading: initiatigOrder }] =
    useInitiateOrderMutation();

  const handleSubmit = () => {
    if (swapDestinations?.send.balance > (transactionCharge?.data?.chargeableAmount || 0)) {
      let data = {
        payload: {
          inboundCurrencyId: swapDestinations?.receive.currencyId,
          outboundCurrencyId: swapDestinations?.send.currencyId,
          inboundAmount: Number(amtToSwap * (rate.data.rate || 0) ),
          outboundAmount: amtToSwap,
          bankRecordId: null,
          phoneNumber: "08032000169",
          otp: otp,
          pin: "",
        },
        orderType: "Swap",
        userId: userId,
      };
     
      setError("");
      initateOrder(data)
        .unwrap()
        .then((res) => res.success && handleModal(4))
        .catch((error) => setError(error.data.message));
    } else {
      setError("Insufficient balance");
    }

  };

  return (
    <Modal
      close={true}
      title={"Swap"}
      description={"Confirm swap details"}
      handleCloseModal={closeModal}
      handleGoBack={() => handleModal(1)}
      goBack={true}
    >
      <>
        <div className="gap-3 px-3 py-8 space-y-3 bg-white rounded dark:bg-clrDarkBg ">
          <div className="flex items-center gap-2">
            <div className="w-[30px] h-[30px] rounded-[50%] bg-white dark:bg-clrDarkBg flex justify-center items-center">
              <img
                src={swapDestinations?.send.iconUrlSvg}
                alt={swapDestinations?.send.symbol}
                width={20}
                height={20}
                className="w-5 h-5 md:w-7 md:h-7"
              />
            </div>
            <p className="mr-auto text-xs font-semibold text-clrGray dark:text-clrPlaceholder">
              {numberWithCommas(amtToSwap, 2)}
            </p>
            <p className="text-xs font-semibold md:text-sm text-clrTextGray dark:text-white dark:font-medium ">
              {swapDestinations?.send.symbol}
            </p>
          </div>

          <div className="grid place-content-center">
            <img src={SwitchSwap} alt="more options" width={20} height={20} />
          </div>

          <div className="flex items-center gap-2">
            <div className="w-[30px] h-[30px] rounded-[50%] bg-white dark:bg-clrDarkBg flex justify-center items-center">
              <img
                src={swapDestinations?.receive.iconUrlSvg}
                alt={swapDestinations?.receive.symbol}
                width={20}
                height={20}
              />
            </div>
            <p className="mr-auto text-xs font-semibold text-clrGray dark:text-clrPlaceholder">
              {rate && numberWithCommas(amtToSwap * (rate.data.rate || 0), 4)}
            </p>
            <p className="text-xs font-semibold md:text-sm text-clrTextGray dark:text-white dark:font-medium ">
              {swapDestinations?.receive.symbol}
            </p>
          </div>
          <div>
            <p className="mb-3 text-[10px] font-semibold text-clrTextGray dark:text-clrPlaceholder">
              Transaction Details
            </p>
            <div className="space-y-3">
              <p className="flex justify-between">
                <span className="text-[10px] font-medium text-clrGray dark:text-clrPlaceholder">
                  Rate
                </span>
                <span className="text-[10px] font-semibold text-clrTextGray dark:text-white dark:font-medium">
                  1 {swapDestinations.send.symbol} ={" "}
                  {numberWithCommas(rate?.data.rate, 4)}{" "}
                  {swapDestinations.receive.symbol}
                </span>
              </p>
              {/* <p className="flex justify-between">
                <span className="text-[10px] font-medium text-clrGray">
                  Inverse rate
                </span>
                <span className="text-[10px] font-semibold text-clrTextGray">
                  1 {swapDestinations.from.symbol} ={" "} {numberWithCommas(1 / rate?.data.rate, 4)}{" "}{swapDestinations.to.symbol}
                </span>
              </p> */}
              <p className="flex justify-between">
                <span className="text-[10px] font-medium text-clrGray dark:text-clrPlaceholder">
                  Transaction Fees
                </span>
                <span className="text-[10px] font-semibold text-clrTextGray dark:text-white dark:font-medium ">
                  {gettingCharge
                    ? "..."
                    : transactionCharge?.data.transactionCharge}
                </span>
              </p>
              <p className="flex justify-between">
                <span className="text-[10px] font-medium text-clrGray dark:text-clrPlaceholder">
                  Total
                </span>
                <span className="text-[10px] font-semibold text-clrTextGray dark:text-white dark:font-medium ">
                  {gettingCharge
                    ? "..."
                    : numberWithCommas(transactionCharge?.data.chargeableAmount || 0, 4)}
                </span>
              </p>
              <p className="flex justify-between">
                <span className="text-[10px] font-medium text-clrGray dark:text-clrPlaceholder">
                  You will receive
                </span>
                <span className="text-[10px] font-semibold text-clrTextGray dark:text-white dark:font-medium">
                  {rate &&
                    numberWithCommas((amtToSwap - (transactionCharge?.data.transactionCharge || 0)) * (rate.data.rate || 0), 4)}{" "}
                  {swapDestinations?.receive.symbol}
                </span>
              </p>
            </div>
          </div>
        </div>
        <ErrorMsg errMsg={errMsg} />
        <div className="md:w-[80%] mt-6 mx-auto ">
          <Button
            type="button"
            text="Confirm"
            loading={initiatigOrder}
            disabled={initiatigOrder}
            handleClick={handleSubmit}
          />
        </div>
      </>
    </Modal>
  );
};

export const SwapStatus: React.FC<SwapDetailProps> = ({
  closeModal,
  swapDestinations,
  amtToSwap,
  rate,
}) => {
  const { data: transactionCharge } =
    useGetTransactionChargeQuery({
      transactionCategory: "Swap",
      currencyId: swapDestinations?.send.currencyId,
      channel: "XendBridge",
      amount: amtToSwap,
    });
  return (
    <Modal
      close={true}
      title={"Swap"}
      description={"Confirm swap details"}
      handleCloseModal={closeModal}
      goBack={false}
    >
      <>
        <div className="flex flex-col items-center justify-center gap-3 px-3 py-8 space-y-3 bg-white dark:bg-clrDarkBg rounded ">
          <SuccessIcon className="w-32 h-32 md:w-44 md:h-44" />
          <div className="mb-5 text-center">
            <h2 className="mb-3 text-base font-semibold text-clrGreen2">
              Success
            </h2>
            <p className="text-[10px] font-medium text-clrGray66 dark:text-clrPlaceholder">
              {swapDestinations?.send.symbol} Successfully Swapped
            </p>
          </div>
          <div className="space-y-1 text-center">
            <p className="text-[9px] font-medium text-clrGray66 dark:text-clrPlaceholder">
              Amount
            </p>
            <p className="text-xl font-bold text-black dark:text-white">
              {rate &&
                numberWithCommas((amtToSwap - (transactionCharge?.data.transactionCharge || 0)) * (rate || 0), 4)}{" "}
              {swapDestinations?.receive.symbol}
            </p>
            <p className="text-[9px] font-medium text-clrGray66 dark:text-clrPlaceholder">
              {amtToSwap} {swapDestinations?.send.symbol}
            </p>
          </div>
        </div>
        <div className="w-[80%] mt-6 mx-auto ">
          <Button type="button" text="Close" handleClick={closeModal} />
        </div>
      </>
    </Modal>
  );
};

export const PendingSwap: React.FC<SwapDetailProps> = ({ closeModal }) => {
  return (
    <Modal
      close={true}
      title={"Pending Swap order"}
      description={"You currently have a pending swap order"}
      handleCloseModal={closeModal}
      goBack={false}
    >
      <>
        <div className="flex flex-col items-center justify-center gap-3 px-3 py-8 space-y-3 bg-white rounded dark:bg-clrDarkBg ">
          <p className="text-center dark:text-clrPlaceholder">
            {" "}
            You cannot perform another transaction until your pending order is
            completed{" "}
          </p>
        </div>
        <div className="w-[80%] mt-6 mx-auto ">
          <Button type="button" text="Close" handleClick={closeModal} />
        </div>
      </>
    </Modal>
  );
};
